import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import Social from "../Social/Social";

import "./footer.scss";

const Footer = ({motionMargin}) => {
	const { pathname } = useLocation();
	const preventLink = pathname === "/" ? " preventLink" : "";

	return (
		<footer className="footer">
			<motion.div
				initial={{ opacity: 0, y: "10%" }}
				whileInView={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.6, delay: 0.1 }}
				viewport={{ once: true, amount: 0.1 }}
				className="container"
			>
				<div className="footer-wrapper">
					<div className="footer-column">
						<Link to="/" className={`logo${preventLink}`}>
							<span>Cūox</span>
							<span>Cūox</span>
						</Link>
						<address className="contacts">
							<span>New South Head Rd, Double Bay NSW 2028, New York</span>
							<div className="contacts-item phone">
								<span>P:</span>
								<Link to="tel:+1300877503">1300 877 503</Link>
							</div>
							<div className="contacts-item email">
								<span>E:</span>
								<Link to="mailto:contact@xudu.com">contact@xudu.com</Link>
							</div>
						</address>
					</div>
					<div className="footer-column">
						<div className="footer-column-title">Navigation</div>
						<div className="footer-column-links">
							<Link to="/about" className="viewall">
								<span>About</span>
								<span>About</span>
							</Link>
							<Link to="/work" className="viewall">
								<span>Work</span>
								<span>Work</span>
							</Link>
							<Link to="/journal" className="viewall">
								<span>Journal</span>
								<span>Journal</span>
							</Link>
							<Link to="/contact" className="viewall">
								<span>Contact</span>
								<span>Contact</span>
							</Link>
						</div>
					</div>
					<div className="footer-column">
						<div className="footer-column-title">Work</div>
						<div className="footer-column-links">
							<Link to="/work/00001" className="viewall">
								<span>Innovate Impact</span>
								<span>Innovate Impact</span>
							</Link>
							<Link to="/work/00002" className="viewall">
								<span>Strategic Fusion</span>
								<span>Strategic Fusion</span>
							</Link>
							<Link to="/work/00003" className="viewall">
								<span>Digital Nexu</span>
								<span>Digital Nexu</span>
							</Link>
							<Link to="/work/00004" className="viewall">
								<span>Epic Horizon</span>
								<span>Epic Horizon</span>
							</Link>
							<Link to="/work/00005" className="viewall">
								<span>Inspire Craft</span>
								<span>Inspire Craft</span>
							</Link>
							<Link to="/work/00006" className="viewall">
								<span>Dynamic Echo</span>
								<span>Dynamic Echo</span>
							</Link>
						</div>
					</div>
					<div className="footer-column">
						<div className="footer-column-title">Follow us</div>
						<Social />
					</div>
				</div>
				<div className="copyright">© Built with React</div>
			</motion.div>
		</footer>
	);
};

export default Footer;
