import './spinner.scss'

const Spinner = () => {

    return (
        <div className="spinner">
            <div className="spinner-box">
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Spinner