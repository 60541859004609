import { Link } from "react-router-dom";

import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.svg";
import instagram from "../../assets/instagram.svg";
import dribbble from "../../assets/dribbble.svg";
import "./social.scss";

const Social = () => {
	return (
		<div className="social-content">
			<Link to="https://twitter.com/" target="_blank" rel="noreferrer">
				<img src={twitter} alt="twitter" width="20" height="20" />
				<img src={twitter} alt="twitter" width="20" height="20" />
			</Link>
			<Link to="https://www.linkedin.com/" target="_blank" rel="noreferrer">
				<img src={linkedin} alt="linkedin" width="20" height="20" />
				<img src={linkedin} alt="linkedin" width="20" height="20" />
			</Link>
			<Link to="https://www.instagram.com/" target="_blank" rel="noreferrer">
				<img src={instagram} alt="instagram" width="20" height="20" />
				<img src={instagram} alt="instagram" width="20" height="20" />
			</Link>
			<Link to="https://dribbble.com/" target="_blank" rel="noreferrer">
				<img src={dribbble} alt="dribbble" width="20" height="20" />
				<img src={dribbble} alt="dribbble" width="20" height="20" />
			</Link>
		</div>
	);
};

export default Social;
