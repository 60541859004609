import { useEffect, useState } from "react";
import { Link } from "react-scroll";

import "./pageup.scss";

const PageUp = () => {
	const [scrollDir, setScrollDir] = useState("scrolling down");
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		let lastScrollY = window.scrollY;

		const updateScrollDir = () => {
			const scrollY = window.scrollY;
			setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
			lastScrollY = scrollY > 0 ? scrollY : 0;
		};

		const onScroll = () => {
			window.requestAnimationFrame(updateScrollDir);
		};

		window.addEventListener("scroll", () => {
			onScroll();
			handleScroll();
		});

		return () =>
			window.removeEventListener("scroll", () => {
				onScroll();
				handleScroll();
			});
	}, [scrollDir, visible]);

	const handleScroll = () => {
		let scrollTop = document.documentElement.scrollTop;
		scrollTop > 700 ? setVisible(true) : setVisible(false);
	};

	const clazz =
		scrollDir === "scrolling up" || !visible ? " hidePageUpBtn" : "";

	return (
		<Link
			to="up"
			smooth={"easeInOutQuart"}
			duration={1500}
			className={`pageup${clazz}`}
		>
			Up
		</Link>
	);
};

export default PageUp;
