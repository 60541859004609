import { NavLink } from "react-router-dom";
import "./menu.scss";

const Menu = ({ menuOpen, onMenuClose }) => {
	const clazz = menuOpen ? " active" : "";
	return (
		<nav>
			<div className={`menu${clazz}`}>
				<div className="menu-items">
					<NavLink end to="/about" onClick={onMenuClose}>
						<span>01</span>
						<div className="menu-item">
							<div className="menu-item-text">About</div>
							<div className="menu-item-text">About</div>
						</div>
					</NavLink>
					<NavLink end to="/work" onClick={onMenuClose}>
						<span>02</span>
						<div className="menu-item">
							<div className="menu-item-text">Work</div>
							<div className="menu-item-text">Work</div>
						</div>
					</NavLink>
					<NavLink end to="/journal" onClick={onMenuClose}>
						<span>03</span>
						<div className="menu-item">
							<div className="menu-item-text">Journal</div>
							<div className="menu-item-text">Journal</div>
						</div>
					</NavLink>
					<NavLink end to="/contact" onClick={onMenuClose}>
						<span>04</span>
						<div className="menu-item">
							<div className="menu-item-text">Contact</div>
							<div className="menu-item-text">Contact</div>
						</div>
					</NavLink>
				</div>
			</div>
		</nav>
	);
};

export default Menu;
