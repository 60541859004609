import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Social from "../Social/Social";

import "./header.scss";

const Header = ({ menuOpen, onToggleMenu, onMenuClose }) => {

	const [visible, setVisible] = useState(false);

    const handleScroll = () => {
        let scrollTop = document.documentElement.scrollTop;
        if (scrollTop > 20) {
            setVisible(true);
        } else if (scrollTop <= 20) {
            setVisible(false);
        }
    }

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	  
		return () => {
		  window.removeEventListener("scroll", handleScroll);
		};
	}, [visible]);

	const clazz = menuOpen ? " active" : "";
	const headerColored = visible ? ' colored' : '';

	return (
		<header className={`header${headerColored}`}>
			<div className="container">
				<div className="header-wrapper">
					<NavLink end to="/" className="logo" onClick={onMenuClose}>
						<span>Cūox</span>
						<span>Cūox</span>
					</NavLink>
					<div onClick={onToggleMenu} className={`hamburger${clazz}`}>
						<span></span>
						<span></span>
					</div>
					<Social />
				</div>
			</div>
		</header>
	);
};

export default Header;
